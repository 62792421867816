import React from 'react'
import NursingHome from '../../assets/img/Nursing-Homes.jpg'
import { Link } from 'react-router-dom'

const NursingHomes = () => {
  return (
    <div className='alpha-employee-section services'>
        <div className='alpha-employee-section-row col-rev'>
            <div className='alpha-employee-section-col'>
                <img className='hospital-staff' src={NursingHome} alt='Hospital Staff' />
            </div>
            <div className='alpha-employee-section-col flex-end'>
                <h2>Nursing Homes</h2>
                <p>Compassionate Care for Our Elders: Nursing homes have a special need for caregivers who bring compassion and patience along with their professional qualifications. We supply nursing homes with staff who are not only highly skilled in their medical capacities but also excel in providing the warmth and understanding that elder care requires. From registered nurses and LPNs to care assistants and support staff, we ensure your residents receive the best possible care.</p>
                {/* <div className='alpha-spacer-x1'></div> */}
                <Link to='/contact'>
                <button className='alpha-primaryBtn'>Start Hiring!</button>
                </Link>
            </div>
        </div>
    </div>
  )
}

export default NursingHomes
