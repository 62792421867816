import React from 'react'
import { Route, Routes } from "react-router"
import "./App.css"
import Home from './pages/home/Home'
import About from './pages/about/About'
import Error from './pages/error/Error'
import Services from './pages/services/Services'
import Contact from './pages/contact/Contact'
import Employment from './pages/employment/Employment'

const AllRoutes = () => {
  return (
    <div className='main'>
        <Routes>
            <Route path='*' element={<Error />} />
            <Route path='/' element={<Home />} />
            <Route path='/about' element={<About />} />
            <Route path='/services' element={<Services />} />
            <Route path='/contact' element={<Contact />} />
            <Route path="/employment" element={<Employment />} />
        </Routes>
    </div>
  )
}

export default AllRoutes
