import React from 'react'
import PagesHero from '../about/pagesHero/PagesHero'
import HospitalStaff from './HospitalStaff'
import './services.css'
import NursingHomes from './NursingHomes'
import HomeCare from './HomeCare'
import AboutSection from '../home/aboutSection/AboutSection'

const Services = () => {
  return (
    <div className='alpha-services'>
        <div className='alpha-services-row'>
            <div className='alpha-services-col'>
                <PagesHero title="Services" />
                <AboutSection
                  title=""
                  desc="At Alpha Staffing Agency, we understand the diverse needs of healthcare facilities. From bustling hospitals and caring nursing homes to the personalized environment of home care, our staffing solutions are designed to meet the specific demands of each setting. Our commitment is to provide exceptional healthcare professionals who bring expertise, compassion, and dedication to their roles."
                  btn="Talk to Us"
                  url="tel:+13024390924"
                />
                <HospitalStaff />
                <NursingHomes />
                <HomeCare />
            </div>
        </div>
    </div>
  )
}

export default Services
