import Phone from "../../icons/Phone"
import MobileMenu from "../mobile/MobileMenu"
import drawerIcon from "../../../assets/img/Alpha-staffing-main-logo.png"
import "./drawer.css"
import Email from "../../icons/Email"


const MenuDrawer = ({ isOpen, close }) => {

    return (
        <div className={`bts_drawer_container ${isOpen}`}>
            <img src={drawerIcon} className="bts_drawer_logo" alt="drawer_logo" />
            <MobileMenu close={close}/>
            <div className="bts_drawer_footer">
                <Email height={25} width={25} color="#18345F" />
                <Phone height={25} width={25} color="#18345F" />
            </div>
        </div>
    )
}

export default MenuDrawer