import React from 'react'
import HospitalStaffs from '../../assets/img/Hospital-Staff.jpg'
import { Link } from 'react-router-dom'

const HospitalStaff = () => {
  return (
    <div className='alpha-employee-section services'>
        <div className='alpha-employee-section-row'>
            <div className='alpha-employee-section-col'>
                <h2>Hospital Staff</h2>
                <p>Tailored Staffing for High-Demand Environments: Hospitals require a wide range of healthcare professionals to meet the needs of their diverse patient population. We specialize in placing skilled personnel in various hospital roles, including nurses, technicians, therapists, and administrative staff. Our candidates are prepared to face the dynamic and fast-paced hospital environment, ensuring quality care and operational efficiency.</p>
                <div className='alpha-spacer-x1'></div>
                <Link to='/contact'>
                <button className='alpha-primaryBtn'>Start Hiring!</button>
                </Link>
            </div>
            <div className='alpha-employee-section-col'>
                <img className='hospital-staff' src={HospitalStaffs} alt='Hospital Staff' />
            </div>
        </div>
    </div>
  )
}

export default HospitalStaff
