import React from 'react'
import './navbar.css'
import Phone from '../../icons/Phone'
import { Link } from 'react-router-dom'

const Navbar = () => {
  return (
    <div className='alpha-navbar-container'>
        <ul className='alpha-navitems'>
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/about">About</Link>
            </li>
            <li>
              <Link to="/services">Services</Link>
            </li>
            <li>
              <Link to="/employment">Employment</Link>
            </li>
            <li>
              <Link to="/contact">Contact Us</Link>
            </li>
        </ul>
        <Link to="/contact">
          <button className='alpha-primaryBtn'>
                  <span><Phone width={20} height={20} color="#f9f9f9" /></span>
                  <p>Get Started</p>  
          </button>
        </Link>
    </div>
  )
}

export default Navbar
