import React, { useState } from 'react'
import './header.css'
import AlphaLogo from '../../assets/img/Alpha-staffing-main-logo.png'
import Navbar from './navbar/Navbar'
import Hamburger from '../icons/Hamburger'
import MenuDrawer from '../menu/drawer/MenuDrawer'
import Close from '../icons/Close'
import { Link } from 'react-router-dom'

const Header = () => {

  const [ open, setOpen ] = useState("bts_slide_in")

    const handle_click_open = () => {
        setOpen("bts_slide_out")
        console.log('clicked')
    }

    const handle_click_close = () => {
        setOpen("bts_slide_in slide_in")
    }

  return (
    <div className='alpha-header'>
        <div className='alpha-header-row'>
            <div className='alpha-header-col'>
              <Link to="/">
                <img className='alpha-logo' src={AlphaLogo} alt='alpha staffing main logo' />
              </Link>
            </div>
            <div className='alpha-header-col'>
                <Navbar />
                <div className='display-none' onClick={handle_click_open} >
                  <Hamburger width={24} height={24} color="#18345F" />
                </div>
                {open === "bts_slide_out" ?
                <div className='display-none bts_close_cont' onClick={handle_click_close} >
                  <Close width={24} height={24} color="#18345F" />
                </div> : "" }
                <MenuDrawer isOpen={open} close={handle_click_close} />
            </div>
        </div>
    </div>
  )
}

export default Header
