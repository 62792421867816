import React from 'react'
import './employersect.css'
import Pic from '../../../assets/img/Hospital-Staff.jpg'
import { Link } from 'react-router-dom'

const EmployerSection = () => {
  return (
    <div className='alpha-employee-section'>
        <div className='alpha-employee-section-row'>
            <div className='alpha-employee-section-col'>
                <h2>Our Values</h2>
                <p>Healthcare facilities trust Alpha Staffing Agency to deliver reliable staffing solutions. Whether you need temporary or permanent nursing staff, we tailor our services to meet your unique requirements. Partner with us to ensure your facility has the right talent to provide exceptional patient care. Some of our values include;</p>
                <li>
                    <b>Excellence:</b> Upholding the highest standards in all we do.
                </li>
                <li>
                    <b>Compassion:</b> Ensuring care and understanding are at the heart of our service.
                </li>
                <li>
                    <b>Integrity:</b> Acting with honesty and transparency at all times.
                </li>
                <li>
                    <b>Innovation:</b> Embracing change and striving for continuous improvement.
                </li>
                <div className='alpha-spacer-x1'></div>
                <Link to="/about">
                    <button className='alpha-primaryBtn'>Learn More!</button>
                </Link>
            </div>
            <div className='alpha-employee-section-col'>
                <img src={Pic} alt="Alpha-Staffing" />
            </div>
        </div>
    </div>
  )
}

export default EmployerSection

// <div className=''>
//                     <h3 className='alpha-coloredText'>98%</h3>
//                     <p>Satisfaction Rate</p>
//                 </div>
//                 <div className='alpha-colored-box'>
//                     <h3 className='alpha-coloredText'>24/7</h3>
//                     <p>Availability</p>
//                 </div>
//                 <div className='alpha-colored-box'>
//                     <h3 className='alpha-coloredText'>95%</h3>
//                     <p>Placement Success</p>
//                 </div>
//                 <div className=''>
//                     <h3 className='alpha-coloredText'>100%</h3>
//                     <p>Compliance</p>
//                 </div>