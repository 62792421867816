import { Link } from "react-router-dom"
import "./mobilemenu.css"

const MobileMenu = ({ close }) => {

    return (
        <div className="bts_mobile_menu">
            <ul className="bts_mobile_navitems">
                <Link to="/" onClick={close}>
                    <li className="bts_mobile_navitem">.Home</li>
                </Link>
                <Link to="/about" onClick={close} >
                    <li className="bts_mobile_navitem">.About</li>
                </Link>
                <Link to="/services" onClick={close} >
                    <li className="bts_mobile_navitem">.Services</li>
                </Link>
                <Link to="/employment" onClick={close} >
                    <li className="bts_mobile_navitem">.Employment</li>
                </Link>
                <Link to="/contact" onClick={close}>
                    <li className="bts_mobile_navitem">Contact Us</li>
                </Link>
            </ul>
        </div>
    )
}

export default MobileMenu