import React from 'react'
import './mission.css'
import Quote from '../../../components/icons/Quote'

const Mission = () => {
  return (
    <div className='alpha-mission'>
        <div className='alpha-mission-row'>
            <div className='alpha-mission-col'>
                <h2>Our Mission</h2>
                <Quote width={50} height={50} color="#D3D3D3" />
                <blockquote>
                To enhance healthcare delivery by providing top-tier staffing solutions, ensuring that facilities are supported by skilled and compassionate healthcare professionals.
                </blockquote>
            </div>
            <div className='alpha-spacer-x2' ></div>
            <div className='alpha-mission-col'>
                <h2>Our Vision</h2>
                <Quote width={50} height={50} color="#D3D3D3" />
                <blockquote>
                To be the foremost choice for healthcare facilities and professionals alike, setting the standard for quality, reliability, and integrity in healthcare staffing.
                </blockquote>
            </div>
            <div className='alpha-spacer-x2' ></div>
            <div className='alpha-mission-col'>
                <h2>Our Commitment</h2>
                <Quote width={50} height={50} color="#D3D3D3" />
                <blockquote>
                Across all services, Alpha Staffing Agency is committed to excellence. We rigorously vet our healthcare professionals to ensure they meet our high standards and match the specific needs of your facility or home care situation. With our comprehensive support and dedication to quality, we are your partner in healthcare staffing, ready to meet the challenges of today's healthcare demands.
                </blockquote>
            </div>
        </div>
    </div>
  )
}

export default Mission
