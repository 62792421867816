import React, { useState } from 'react'
import './contactsect.css'
import ContactImg from '../../../assets/img/contact-img.jpg'
import { ValidationError, useForm } from '@formspree/react';
import { Link } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';

const ContactSection = () => {

    const [ isCaptchaVerified, setIsCaptchaVerified ] = useState(false)
    const [ showError, setShowError ]  = useState(false)

    function onChange(value) {
        if (value) {
            setIsCaptchaVerified(true);
        }
    }
    
    const handleClick = (e) => {
        if (isCaptchaVerified === false) {
            e.preventDefault()
            setShowError(true);
        } else {
            console.log("verified")
        }
    }

    const [state, handleSubmit] = useForm("xzbnagpq");
    if (state.succeeded) {
      return <p>Thanks for reaching out! Be expecting a reply from us very soon via Email.</p>;
    }

    const ErrorMessage = () => {
        return (
            <div>
                <p className='alpha-error-captcha'>Captcha not Verified</p>
            </div>
        )
    }

  return (
    <div className='alpha-contact-section'>
        <div className='alpha-contact-section-row'>
            <div className='alpha-contact-section-col'>
                <div className='alpha-contact-form-window'>
                    <div className='alpha-contact-form-window-header'>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                    <div className='alpha-contact-form-window-body'>
                        <div>
                            <h3>Drop us a line</h3>
                            <p>We will get in touch right away</p>
                        </div>
                        <form onSubmit={handleSubmit}>
                            <input type='text' className='alpha-contact-input' name='name' placeholder='Enter your name' required />
                            <ValidationError
                                prefix='name'
                                field='name'
                                errors={state.errors}
                            />
                            <input type='email' className='alpha-contact-input' name='email' placeholder='Your work email' required />
                            <ValidationError
                                prefix='email'
                                field='email'
                                errors={state.errors}
                            />
                            <input type='text' className='alpha-contact-input' name='phone' placeholder='Your phone number' required />
                            <ValidationError
                                prefix='phone'
                                field='phone'
                                errors={state.errors}
                            />
                            <textarea className='alpha-contact-input' name='message' rows={5} cols={30} ></textarea>
                            <ValidationError
                                prefix='message'
                                field='message'
                                errors={state.errors}
                            />
                            <ReCAPTCHA
                                sitekey="6LfhlYspAAAAAJYIVJV3qRkrFrSidrD7TxyawilD"
                                onChange={onChange}
                            />
                            {
                                showError ? <ErrorMessage /> : "" 
                            }
                            {/* <div className='alpha-spacer-x1'></div> */}
                            <div className='alpha-contact-submit' >
                                <button type='submit' className='alpha-primaryBtn' disabled={state.submitting} onClick={handleClick}>Send</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div className='alpha-contact-section-col'>
                <img className='alpha-contact-img' src={ContactImg} alt='Contact-us-img' />
                <div className='alpha-spacer-x1'></div>
                <h2>Connect with Alpha Staffing Agency</h2>
                <p>Ready to elevate your healthcare staffing experience? Contact Alpha Staffing Agency today. Our team is here to answer your questions, discuss your staffing needs, and provide tailored solutions that exceed your expectations.</p>
                <div className='alpha-spacer-x1'></div>
                <Link to='tel:+13024390924'>
                    <button className='alpha-primaryBtn'>Call Us Today!</button>
                </Link>
            </div>
        </div>
    </div>
  )
}

export default ContactSection
