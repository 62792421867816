import React from 'react'
import './pageshero.css'

const PagesHero = ({ title }) => {
  return (
    <div className='alpha-pages-hero'>
        <div className='alpha-pages-hero-row'>
            <div className='alpha-pages-hero-col'>
                <h1>{title}</h1>
            </div>
        </div>
    </div>
  )
}

export default PagesHero
