import React from 'react'
import "./error.css"
import { Link } from 'react-router-dom'

const Error = () => {
  return (
    <div className='alpha-error'>
        <h1 className='alpha-colored-text'>404</h1>
        <p>The page you're looking for does not exist.</p>
        <Link to="/">
            <p>Return to home</p>
        </Link>
    </div>
  )
}

export default Error
