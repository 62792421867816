import React from 'react'
import "./about.css"
import PagesHero from './pagesHero/PagesHero'
import Intro from './intro/Intro'
import Mission from './mission/Mission'
import ContactSection from '../home/contactSection/ContactSection'
import WhyUs from './why-us/WhyUs'

const About = () => {
  return (
    <div className='alpha-about'>
        <div className='alpha-about-row'>
            <div className='alpha-about-col'>
                <PagesHero title="About Us" />
                <Intro />
                <Mission />
                <WhyUs />
                <ContactSection />
            </div>
        </div>
    </div>
  )
}

export default About
