import React from 'react'
import './footer.css'
import ALphaLogo from '../../assets/img/Alpha-staffing-main-logo.png'
import Email from '../icons/Email'
import Phone from '../icons/Phone'
import { Link } from 'react-router-dom'

const Footer = () => {
  return (
    <div className='alpha-footer'>
        <div className='alpha-footer-row'>
            <div className='alpha-footer-col'>
                <img src={ALphaLogo} alt='Alpha Staffing Logo' />
                <p>At Alpha Staffing Agency, we're more than just a healthcare staffing company – we're your partner in delivering quality patient care. Join us in making a difference in healthcare staffing.</p>
                  <div className='alpha-socials'>
                    {
                    //<div className='alpha-social-icon'>
                      //  <X height={18} width={18} color="#ffffff" />
                    //</div>
                    //<div className='alpha-social-icon'>
                     //   <Instagram height={18} width={18} color="#ffffff" />
                    //</div>
                    //<div className='alpha-social-icon'>
                    //    <Facebook height={18} width={18} color="#ffffff" />
                    //  </div>
                    }
                    <Link to="mailto:service@alphastaffing.us">
                    <div className='alpha-social-icon'>
                        <Email height={18} width={18} color="#ffffff" />
                    </div>
                    </Link>
                    <Link to="tel:+13024508612">
                    <div className='alpha-social-icon'>
                        <Phone height={18} width={18} color="#ffffff" />
                    </div>
                    </Link>
                </div>
                <div className='alpha-spacer-x1'></div>
            </div>
              <div className='alpha-footer-col'>
                  <h3>Alpha Staffing Agency Can Assist You with All of Your Healthcare Needs</h3>
                  <Link to="/contact">
                    <button className="alpha-primaryBtn">Send us a message</button>
                  </Link>
                  {// <h3>Subscribe to our Newsletter</h3>
               // <div className='alpha-spacer-x1'></div>
               // <form action=''>
               //     <input type='email' className='alpha-contact-input' name='email' placeholder='Your work email' required />
                   // <div className='alpha-spacer-x1'></div>
                 //   <button className='alpha-primaryBtn'>Subscribe</button>
                      //  </form> 
                  }
            </div>
        </div>
        <hr />
        <div className='alpha-footer-row'>
            <div className='alpha-subfooter-col'>
                <center>
                <p>Copyright @ 2024 Alpha Staffing Agency | All rights reserved.</p>
                </center>
            </div>
        </div>
    </div>
  )
}

export default Footer
