import React from 'react'
import './hero.css'
import { Link } from 'react-router-dom'

const Hero = () => {
  return (
    <div className='alpha-hero'>
        <div className='alpha-hero-row'>
            <div className='alpha-hero-col'>
                
            </div>
            <div className='alpha-hero-col'>
              <h1>Connecting Excellence in <span className='alpha-colored-text'>Healthcare</span> with Top Talent</h1>
              <p>Your partner in delivering compassionate, skilled, and dedicated healthcare professionals to meet the demands of your facility.</p>
              <div className='alpha-spacer-x2'></div>
              <Link to="/services">
                <button className='alpha-primaryBtn hero-btn'>
                  Learn More!
                </button>
              </Link>
            </div>
        </div>
    </div>
  )
}

export default Hero
