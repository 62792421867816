import React from 'react'
import PagesHero from '../about/pagesHero/PagesHero'
import EmploymentForm from './employmentform/EmploymentForm'

const Employment = () => {
    return (
        <div className="alpha-employment">
            <div className="alpha-employment-row">
                <div className="alpha-employment-col">
                    <PagesHero title="Employment" />
                    <div className='alpha-spacer-x1'></div>
                    <div>
                        <EmploymentForm />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Employment