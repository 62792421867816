import React from 'react'
import './servicessect.css'
import HospitalStaff from '../../../assets/img/hospital-nursing-staff.png'
import NursingHome from '../../../assets/img/nurding-homes.png'
import HomeCare from '../../../assets/img/home-care.png'

const ServicesSection = () => {
  return (
    <div className='alpha-services-section'>
        <div className='alpha-services-section-row'>
            <div className='alpha-services-section-col'>
                <center>
                <h2>Tailored Healthcare Staffing Solutions</h2>
                </center>
            </div>
            <div className='alpha-services-section-col'>
                <div>
                    <h3>Hospital Staff</h3>
                    <div className='alpha-spacer-x1'></div>
                    <p>Hospitals require a wide range of healthcare professionals to meet the needs of their diverse patient population. We specialize in placing skilled personnel in various hospital roles, including nurses, technicians, therapists, and administrative staff. </p>
                    <img src={HospitalStaff} alt="Hospital Bursing Staff" />
                </div>
                <div>
                    <h3>Nursing Homes</h3>
                    <div className='alpha-spacer-x1'></div>
                    <p>Nursing homes have a need for caregivers who bring compassion and patience with their professional qualifications. We supply nursing homes with highly skilled staff that excel in providing the warmth and understanding that elder care requires.</p>
                    <img src={NursingHome} alt='Nursing Homes' />
                </div>
                <div>
                    <h3>Home Care</h3>
                    <div className='alpha-spacer-x1'></div>
                    <p>Home care services requires a blend of skill, compassion, and adaptability. We connect families and individuals with healthcare professionals who are experienced in delivering personalized care at home, from routine assistance to complex medical needs.</p>
                    <img src={HomeCare} alt='Home Care' />
                </div>
            </div>
        </div>
    </div>
  )
}

export default ServicesSection
