import React from 'react'
import PagesHero from '../about/pagesHero/PagesHero'
import ContactSection from '../home/contactSection/ContactSection'

const Contact = () => {
  return (
    <div className='alpha-contact'>
        <div className='alpha-contact-row'>
            <div className='alpha-contact-col'>
                <PagesHero title="Contact Us" />
                <div className='alpha-spacer-x1'></div>
                <ContactSection />
            </div>
        </div>
    </div>
  )
}

export default Contact
