import React from 'react'
import HomeCares from '../../assets/img/Home-care.jpg'
import { Link } from 'react-router-dom'

const HomeCare = () => {
  return (
    <div className='alpha-employee-section services'>
        <div className='alpha-employee-section-row'>
            <div className='alpha-employee-section-col'>
                <h2>Home Care</h2>
                <p>Personalized Healthcare in the Comfort of Home: Home care services demand a unique blend of skill, compassion, and adaptability. We connect families and individuals with healthcare professionals who are experienced in delivering personalized care at home, from routine assistance to complex medical needs. Our home care professionals are dedicated to enhancing the quality of life for their patients, offering the support and medical attention they need in the familiar surroundings of their own homes.</p>
                <div className='alpha-spacer-x1'></div>
                <Link to='/contact'>
                <button className='alpha-primaryBtn'>Start Hiring!</button>
                </Link>
            </div>
            <div className='alpha-employee-section-col'>
                <img className='hospital-staff' src={HomeCares} alt='Hospital Staff' />
            </div>
        </div>
    </div>
  )
}

export default HomeCare
