import React from 'react'
import './home.css'
import Hero from './hero/Hero'
import AboutSection from './aboutSection/AboutSection'
// import Info from './info/Info'
import ServicesSection from './servicesSection/ServicesSection'
import EmployerSection from './employersSection/EmployerSection'
import ContactSection from './contactSection/ContactSection'

const Home = () => {
  return (
    <div className='alpha-home'>
        <div className='alpha-home-row'>
            <div className='alpha-home-col'>
                <Hero />
                {/* <Info /> */}
                <AboutSection 
                  title="Discover the Alpha Difference"
                  desc="Welcome to Alpha Staffing Agency, where we specialize in bridging the gap between leading healthcare facilities and exceptional healthcare talent. Our commitment to excellence ensures that we match the right professionals with the right opportunities, fostering environments where patients receive the best care possible."
                  btn="Our Commitment" 
                  url="/about"
                  />
                <ServicesSection />
                <EmployerSection />
                <ContactSection />
            </div>
        </div>
    </div>
  )
}

export default Home