import React from 'react'
import "./aboutsection.css"
import { Link } from 'react-router-dom'

const AboutSection = ({ title, desc, btn, url }) => {
  return (
    <div className='alpha-about-section'>
      <div className='alpha-about-section-row'>
        <div className='alpha-about-section-col'>
            <h2 className='alpha-section-title'>{title}</h2>
            <p className='alpha-about-section-desc'>{desc}</p>
            <div className='alpha-spacer-x1'></div>
            <center>
              <Link to={url}>
                <button className='alpha-primaryBtn'>{btn}</button>
              </Link>
            </center>
        </div>
      </div>
    </div>
  )
}

export default AboutSection

