import React from 'react'
import './whyus.css'

const WhyUs = () => {
  return (
    <div className='alpha-why-us'>
        <div className='alpha-why-us-row'>
            <div className='alpha-why-us-col'>
                <h2>Why Choose Alpha Staffing Agency</h2>
                <p>Discover the Alpha difference - where passion meets professionalism, and where your staffing needs are met with precision and care.</p>
            </div>
            <div className='alpha-why-us-col'>
                <div>
                    <h3>Quality Assurance</h3>
                    <p className='count'>1</p>
                    <p>Our thorough vetting process ensures only the most qualified and passionate professionals join your team.</p>
                </div>
                <div>
                    <h3>Personalized Service</h3>
                    <p className='count'>2</p>
                    <p>We take the time to understand the unique needs of both facilities and professionals, ensuring the perfect match.</p>
                </div>
                <div>
                    <h3>24/7 Support</h3>
                    <p className='count'>3</p>
                    <p>Our dedicated support team is always available to assist with any needs or concerns that arise.</p>
                </div>
                <div>
                    <h3>Commitment to Excellence</h3>
                    <p className='count'>4</p>
                    <p>With years of experience in healthcare staffing, we are committed to upholding the highest standards of service and care.</p>
                </div>
            </div>
        </div>
    </div>
  )
}

export default WhyUs
