import React, { useState } from 'react'
import ReCAPTCHA from "react-google-recaptcha"
import { useForm, ValidationError } from '@formspree/react'
import '../employment.css'
import { MdCloudUpload, MdDelete } from 'react-icons/md';
import { AiFillFileImage } from 'react-icons/ai'

const EmploymentForm = () => {

    const [ isCaptchaVerified, setIsCaptchaVerified ] = useState(false)
    const [ showError, setShowError ]  = useState(false)

    function onChange(value) {
        if (value) {
            setIsCaptchaVerified(true);
        }
    }
    
    const handleClick = (e) => {
        if (isCaptchaVerified === false) {
            e.preventDefault()
            setShowError(true);
        } else {
            console.log("verified")
        }
    }

    const [submit, handleSubmit] = useForm("xwkgqpdk")


    const [file, setFile] = useState(null);
    const [filename, setFileName] = useState("No selected file");


    if (submit.succeeded) {
        return <p>Thanks for Submitting your details!</p>
    }

    const ErrorMessage = () => {
        return (
            <div>
                <p className='alpha-error-captcha'>Captcha not Verified</p>
            </div>
        )
    }

    return (
        <div className="alpha-employmentForm">
            <div className="alpha-employmentForm-row">
                <div className="alpha-employmentForm-col">
                    <div className='alpha-contact-form-window'>
                        <div className='alpha-contact-form-window-header'>
                            <div></div>
                            <div></div>
                            <div></div>
                        </div>
                        <div className='alpha-contact-form-window-body'>
                            <div>
                                <h2>Employment Application Form</h2>
                                <div className='alpha-spacer-x1'></div>
                                <p>Partnering with you to find opportunities that align with your career goals, expertise, and lifestyle preferences. <br />From flexible temporary positions to career-defining permanent roles, we are here to support your professional journey.
                                    <br /><br />If you are interested in a career as a caregiver, please submit your application and our staff will contact you when a position opens.</p>
                                <div className='alpha-spacer-x1'></div>
                            </div>
                            <form encType="multipart/form-data" onSubmit={handleSubmit}>
                                <legend>
                                    <label htmlFor="first-name" className="name-label">First Name
                                        <input type='text' className='alpha-contact-input name' name='first-name' placeholder='First Name' required />
                                        <ValidationError prefix="FirstName" field="first-name" errors={submit.errors} />
                                    </label>
                                    <label htmlFor="last-name" className="name-label">Last Name
                                        <input type='text' className='alpha-contact-input name' name='last-name' placeholder='Last Name' required />
                                        <ValidationError prefix="FirstName" field="last-name" errors={submit.errors} />
                                    </label>
                                </legend>
                                <label className="email-label" htmlFor="email">Email Address
                                    <input type='email' className='alpha-contact-input' name='email' placeholder='Your Email Address' required />
                                    <ValidationError prefix="Email" field="email" errors={submit.errors} />
                                </label>
                                <label className="add-label" htmlFor="address">Address
                                    <input type='text' className='alpha-contact-input' name='address' placeholder='Street' />
                                    <ValidationError prefix="Address" field="address" errors={submit.errors} />
                                </label>
                                <legend>
                                    <input type='text' className='alpha-contact-input address' name='city' placeholder='City' required />
                                    <ValidationError prefix="City" field="city" errors={submit.errors} />
                                    <input type='text' className='alpha-contact-input address' name='state' placeholder='State/Province' required />
                                    <ValidationError prefix="State" field="state" errors={submit.errors} />
                                    <input type='text' className='alpha-contact-input address' name='zip' placeholder='Zip/Postal' required />
                                    <ValidationError prefix="ZipCode" field="zip" errors={submit.errors} />
                                </legend>
                                <legend>
                                    <label htmlFor="about">Tell us about yourself
                                        <textarea className='alpha-contact-input long-cont' rows={5} name="about" cols={30} placeholder="Write here" ></textarea>
                                        <ValidationError prefix="About" field="about" errors={submit.errors} />
                                    </label>
                                    <label htmlFor="why-work-for-us">Why do you want to work with us?
                                        <textarea className='alpha-contact-input long-cont' name="why-work-for-us" rows={5} cols={30} placeholder="Write here" ></textarea>
                                        <ValidationError prefix="WhyUs" field="why-work-for-us" errors={submit.errors} />
                                    </label>
                                </legend>
                                <label className='mssg-label' htmlFor="message">Message
                                    <textarea className='alpha-contact-input' rows={5} cols={30} name="message"></textarea>
                                    <ValidationError prefix="Message" field="message" errors={submit.errors} />
                                </label>
                                <label htmlFor="file" className="file-label"
                                    onClick={() => document.querySelector(".resume-upload").click()}
                                >
                                    <input className="resume-upload" type="file" name="file" required hidden
                                        onChange={({ target: { files } }) => {
                                            files[0] && setFileName(files[0].name)
                                            if (files) {
                                                setFile(files[0].name)
                                            }
                                        } }
                                    />
                                    {file ? 
                                        <>
                                            <AiFillFileImage color='#18345F' size={80} />
                                            <p>{filename}</p>
                                            <span className="delete-cont"
                                                onClick={() => {
                                                    setFileName("No Selected File")
                                                    setFile(null)
                                                } }
                                            ><MdDelete color='red' size={20} /> Delete </span>
                                        </> :
                                        <>
                                            <MdCloudUpload color='#18345F' size={80} />
                                            <p className="filename-status" >{filename}</p>
                                            <p>Drop a File here or Click to Upload</p>
                                        </>
                                    }
                                    <ValidationError prefix="Resume" field="file" errors={submit.errors} />
                                </label>

                                <ReCAPTCHA
                                    sitekey="6LfhlYspAAAAAJYIVJV3qRkrFrSidrD7TxyawilD"
                                    onChange={onChange}
                                />
                                {
                                    showError ? <ErrorMessage /> : "" 
                                }

                                {/* <div className='alpha-spacer-x1'></div> */}
                                <div className='alpha-contact-submit' >
                                    <button
                                        className={`alpha-primaryBtn`}
                                        type='submit'
                                        disabled={submit.submitting}
                                        onClick={handleClick}
                                    >Submit</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EmploymentForm