import React from 'react'
import AboutImg from '../../../assets/img/About-img.jpg'
import { Link } from 'react-router-dom'

const Intro = () => {
  return (
    <div className='alpha-employee-section'>
        <div className='alpha-employee-section-row'>
            <div className='alpha-employee-section-col'>
                <h2>Welcome to <span className='alpha-colored-text'>Alpha Staffing Agency</span>. Your Partner in Healthcare Excellence</h2>
                <p>At Alpha Staffing Agency, we stand at the forefront of healthcare staffing, committed to connecting exceptional nursing professionals with healthcare facilities in need. Our journey began with a simple yet powerful mission: to enhance patient care by providing top-tier staffing solutions. With a focus on quality, reliability, and personalized service, we've become a trusted partner for hospitals, nursing homes, and home care providers across the nation.</p>
                <div className='alpha-spacer-x1'></div>
                <Link to='/contact'>
                <button className='alpha-primaryBtn'>Start Hiring!</button>
                </Link>
            </div>
            <div className='alpha-employee-section-col'>
                <img src={AboutImg} alt='Alpha Staffing Agency' />
            </div>
        </div>
    </div>
  )
}

export default Intro
